<template>
  <div>
    <v-container>
      <v-card dense>
      </v-card>
        <v-row>
        <div class="col-md-12 col-sm-12">
          <v-toolbar dense>
            <v-toolbar-title>
              Desinfeccion y Configuracion de Tratamiento termico
            </v-toolbar-title>
          </v-toolbar>
          <v-card dense>
            <v-container>
              <v-row>
                <v-col>
                  <s-select-definition
                    label="Tipo de Cultivo"
                    :def="1173"
                    v-model="filter.TypeCultive"
                  ></s-select-definition>
                </v-col>
              </v-row>
            </v-container>
            <v-row>
              <v-col
                cols="12"
                lg="6"
                md="6"
                v-for="item in itemsTipe"
                :key="item.Idcode"
              >
                <v-card>
                  <v-container fluid grid-list-lg>
                    <v-layout row wrap>
                      <v-flex xs="12">
                        <v-card :color="'#ECEFF1'" class="black--text">
                          <v-layout row>
                            <v-flex xs="7">
                              <v-card-title primary-title>
                                <div>
                                  <div class="headline">
                                    <b> {{ item.SplName }}</b>
                                  </div>
                                  <div style="font-size: 11px">
                                    Fecha configurada:

                                    <v-chip small :color="'info'">
                                      {{
                                        item.TtmDate == null
                                          ? ""
                                          : item.TtmDate
                                      }}
                                    </v-chip>
                                  </div>
                                  <div>
                                    {{ item.DifferenceMinutes }} 
                                  </div>
                                  <div>
                                    <v-chip></v-chip>
                                  </div>
                                </div>
                              </v-card-title>
                            </v-flex>
                            <v-flex xs="5">
                              <v-btn
                                style="
                                  border-radius: 100%;
                                  height: 54%;
                                  width: 38%;
                                  margin: auto;
                                  display: flex;
                                  justify-content: space-evenly;"
                                color="warning"
                                @click="openModal(item)"
                              >
                                <span class="white--text text-h4"
                                  >{{ item.LlpQuantityJaba }}
                                </span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                          <v-divider light></v-divider>
                          <v-card-actions class="pa-3">
                            <v-chip
                              x-small
                              :color="item.HasTreatment == 1 ? 'info' : 'error'">
                              <b>{{
                                item.HasTreatment == 1 ? "TRATAMIENTO ACTIVO" : "TRATAMIENTO INACTIVO"
                              }}</b>
                            </v-chip>
                            <v-spacer></v-spacer>
                            <v-btn color="error" :disabled="item.HasTreatment == 1 ? false: true" rounded @click="end(item)"
                              >Terminar</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-row>
    </v-container>

    <v-dialog
      v-model="openDialog"
      v-if="openDialog"
      transition="dialog-bottom-transition"
      persistent
      fullscreen>
      <model-treatment :item="items" @close="close()"></model-treatment>
    </v-dialog>


  </div>
</template>

<script>

import ModelTreatment from './ModelTreatment.vue';
import _sSamplingLocationService from '@/services/FrozenProduction/SamplingLocationService.js';
import _sTreatment from "@/services/FrozenProduction/FrzTreatmentService";

export default {
  components: { ModelTreatment  },
  
  data() {
    return {
      itemsTipe :[],
      openDialog : false,
      filter: {},
    };
  },

  watch: {
    "filter.TypeCultive"() {
      this.initialize();
    }
  },

  methods: {
    initialize() {
      if(this.filter.TypeCultive != undefined){
        _sSamplingLocationService
        .list(this.filter, this.$fun.getUserID())
        .then(resp => {
          if(resp.status == 200){
            this.itemsTipe = resp.data;
            console.log('asasssss ', this.itemsTipe);
          }
        })
      }
    },

    close() {
      this.openDialog = false;
      this.initialize();
    },

    openModal(val) {

    

      this.items = val;
      this.openDialog = true;
      console.log(val);
    },

    end(item)
    {
      this.$fun.alert("¿Seguro de terminar?", "question")
      .then(r => {
        if(r.value)
        {
          item.UsrUpdateID = this.$fun.getUserID();
          _sTreatment
          .inactivate(item, this.$fun.getUserID())
          .then(resp => {
            if(resp.status == 200)
            {
              this.$fun.alert("Tratamiento finalizado", "success");
              this.initialize();
              return;
            }
          })
        }
      })
    
    }
  }, 

  created () {
    this.initialize();
  }

}

</script>