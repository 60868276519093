<template >
	<v-card>
		<v-row justify="center">
			<v-col cols="12">
				<s-toolbar
					:label="title"
					dark
					color="primary"
					close
					@close="close()"
					class="mb-0"
				></s-toolbar>
			</v-col>

			<v-col cols="12" lg="8" md="8" >
				
				<v-col cols="12">
					<v-card dense>

						<s-crud 
							:title="titletable"
							:filter="filter"
							@save="save($event)"
							add
							
							:config="config"
						>
						<!-- formPermanent -->

							<template v-slot="props">
								<v-container>
								<v-row justify="center" style="margin: auto;">
									<v-col>
										<s-date 
											v-model="props.item.StartDate" 
											label="Fecha"></s-date>
									</v-col>
									<v-col>
										<!-- <s-time v-model="props.item.StartTime"></s-time>  -->
										<!-- <s-text
											label="Hora" 
											v-model="props.item.StartTime" 
											type="time" 
											landscape
											
										></s-text>  -->
										<label slot="before">Hora</label>
											<div class="relojito">
												<datetime 
													title="Hora  :  Minutos" 
													valueZone="America/Lima"  
													type="time" 
													v-model="props.item.StartTime"
													>
												</datetime>
											</div>
										
										
										<!-- <s-text 										 
											v-model="time" 	
											for="props.item.time"
											readonly 									 
										></s-text>  -->
						
									</v-col>
									<v-col>
										<s-select-definition
											autocomplete
											label="Turno"
											:dgr="54"
											:def="1062"
											v-model="props.item.TtmTurn"
										></s-select-definition>
									</v-col>
								</v-row>
								<v-row justify="center" style="margin: auto;">
									<v-col>
										<s-select-definition
										 label="Nivel de agua en tina"
										 :def="1331"
										 v-model="props.item.TypeWaterLevel">

										</s-select-definition>
										<!-- v-model="props.item.TypeWaterLevel"> -->
									<!-- <s-text v-model="props.item.TtmWaterLevel" decimal label="Nivel de agua en tina"></s-text> -->
									</v-col>

									<v-col v-if="item.TypeCultive == 1">
										<s-select-definition
											v-model="props.item.TypeDisinfectant"
											label="Tipo desinfectante"
											:def="1329"
											autocomplete								
										>
										</s-select-definition>
									</v-col>
									<v-col v-if="item.TypeCultive == 1">
										<s-text 
											decimal 
											label="Concentración Inicial (ppm)"
											v-model="props.item.TtmInitialConcentration">
										</s-text>
									</v-col>

									<v-col v-if="item.TypeCultive == 3">
										<s-text
											decimal
											v-model="props.item.TmtPulpTemperature"
											label="Temperatura de pulpa (°C)"
																		
										>
										</s-text>
									</v-col>
									<v-col v-if="item.TypeCultive == 3">
										<s-text 
											decimal 
											label="Temperatura agua caliente en caldero (°C)"
											v-model="props.item.TmtHotWaterBoiler">
										</s-text>
									</v-col>
								</v-row>
								<v-row v-if="item.TypeCultive == 1" justify="center" style="margin: auto;">
									<v-col>
										<s-text 
											decimal 
											label="Repotenciación (ml)"
											v-model="props.item.TtmRepowering">
										</s-text>
									</v-col>						
									<v-col>
									<s-text 
										v-model="props.item.TtmFinalConcentration" 
										decimal label="Concentración Final (ppm)"></s-text>
									</v-col>
									<v-col>
									<s-text 
										v-model="props.item.TtmDiveTime" 
										decimal 
										label="Tiempo de Inmersión (minutos)"></s-text>
									</v-col>
									
								</v-row>

								<v-row v-if="item.TypeCultive == 3" justify="center" style="margin: auto;">
									<v-col>
										<s-text 
											decimal 
											label="Temperatura agua caliente sensor 1 (°C)"
											v-model="props.item.HotWaterWensor1">
										</s-text>
									</v-col>						
									<v-col>
									<s-text 
										v-model="props.item.HotWaterWensor2" 
										decimal label="Temperatura agua caliente sensor 2 (°C)"></s-text>
									</v-col>
									<v-col>
									<s-text 
										v-model="props.item.HeatTreatment" 
										decimal 
										label="Tiempo de tratamiento térmico (segundos)"></s-text>
									</v-col>
									
								</v-row>

								<v-row justify="center" style="margin: auto;">
									<v-col cols="12" lg="4" md="4" >
										<s-select-definition
											clearable
											v-model="props.item.TypeCorrelativeAction"
											:def="1330"
											label="Acción correctiva"
										></s-select-definition> 
									</v-col>
									<v-col cols="12" lg="8" md="8" >
										<s-textarea 
											v-model="props.item.TtmObsCorrelativeAction"
											label="Observaciones"
										>
										</s-textarea>
									</v-col>
								</v-row>

								</v-container>
							</template>

							<template v-slot:TtmState="{row}">
								<v-chip
									x-small 
									:color="row.TtmState == 1 ? 'success' : 'error'"
								>
									{{ row.TtmState == 1 ? "Activo" : "Vencido" }}
								</v-chip>
							</template>

							<template  v-slot:TypeDisinfectantName="{row}" >

							</template>

						</s-crud>

					</v-card>
				</v-col>
			</v-col>
		</v-row>
	</v-card>
</template>
<script>

	import { Datetime } from 'vue-datetime';


	import _sTreatment from "@/services/FrozenProduction/FrzTreatmentService";
	
	export default {

		props: {
			// itemHead:{StartTime: 'time'},
			item: {
				required: true,
				default: null,
				// itemHead:'time',
			},
		},

		components: {
			Datetime,//jmr
		},
		data: () => ({
			time:'time',
			title: '',
			titletable: '',
			filter: {},
			// itemHead:{},
			config: {
				service: _sTreatment,
				model: {
					TtmID: "ID",
					TtmState: "int",
					StartTime: 'time',
					StartDate: "date"
				},
				headers: [
					{ text: "Fecha", value: "StartDate"},
					{ text: "Hora", value: "StartTime"},
					{ text: "Turno", value: "TypeTurnName"},
					{ text: "Nivel de agua", value: "TypeWaterLevelName"},
					// { text: "Desinfectante", value: "TypeDisinfectantName"},
					// { text: "Concentración Inicial", value: "TtmInitialConcentration"},
					// { text: "Repotenciación", value: "TtmRepowering"},
					// { text: "Concentración Final", value: "TtmFinalConcentration"},
					// { text: "Tiempo de Inmersión", value: "TtmDiveTime"},        
					{ text: "Estado", value: "TtmState"},
				],
				// time:"00:55",
			},
		}),

		methods: {
			// changeHour(hour){
				
			// 	console.log("change HORA",hour)//this.$moment(hour).format(this.$const.FormatTimeView) );
			// 	if (!hour) {
			// 		return;
			// 	}
			// 	this.time = this.$moment(hour).format(this.$const.FormatTimeView);
			// },
			intitialize() {
				
				this.filter.SplID = this.item.SplID;
				// _sGenParam
				// 	.search(
				// 		{ PrmName: "TanqueCapacidadCanastilla" },
				// 		this.$fun.getUserID()
				// 	)
				// 	.then(resp => {
				// 		if (resp.status == 200) {
				// 			this.tankCapacity = resp.data.PrmValue;
				// 			console.log(this.tankCapacity);
				// 		}
				// 	});
			},

			close() {
				this.$emit("close");
			},

			save(item) {
				
				item.UsrUpdateID = this.$fun.getUserID();
				item.UsrCreateID = this.$fun.getUserID();
				item.SplID = this.item.SplID;
				item.save();
			}

		},

		created() {

			this.intitialize();
			if(this.item.TypeCultive == 1){
				this.title = "Desinfección de materia prima";
				this.titletable = "Confgiguracion de desinfeción de materia prima";
			}

			if(this.item.TypeCultive == 3){
				this.title = "Tratamiento Térmico";
				this.titletable = "Configuracion de tratamiento Térmico";
			}
		},
		mounted(){
			console.log('cargar hora reloj',this.item.StartTime = this.$fun.formatTimeShortView(this.$moment().format("hh:mm")));
			this.item.StartTime = this.$fun.formatTimeShortView(this.$moment().format("hh:mm"));

			if(this.item.TypeCultive == 1)
			{
				this.config.headers.push({ text: "Desinfectante", value: "TypeDisinfectantName"},
					{ text: "Concentración Inicial", value: "TtmInitialConcentration"},
					{ text: "Repotenciación", value: "TtmRepowering"},
					{ text: "Concentración Final", value: "TtmFinalConcentration"},
					{ text: "Tiempo de Inmersión", value: "TtmDiveTime"},  );
			}

			if(this.item.TypeCultive == 3)
			{
				this.config.headers.push({ text: "Temperatura de pulpa", value: "TmtPulpTemperature"},
					{ text: "Temperatura caldero (°C)", value: "TmtHotWaterBoiler"},
					{ text: "Temperatura sensor 1 (°C)", value: "HotWaterWensor1"},
					{ text: "Temperatura sensor 2 (°C)", value: "HotWaterWensor2"},
					{ text: "Tiempo de tratamiento térmico", value: "HeatTreatment"},  );
			}
		}

		
		};
</script>

<style>
.relojito {
	width:auto;
	height: auto;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(160, 159, 159);
	border-radius: 4px;
	padding: 3px;
  }
  </style>